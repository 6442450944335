<template>
<!-- <ul id="dd_parametrizaciones" class="dropdown-content">
  <li><router-link to="/Carreras">Carreras</router-link></li>
  <li><router-link to="/Planes">Planes</router-link></li>
</ul> -->

<ul id="dd_procesos" class="dropdown-content">
  <li v-if="permitirMenu('ProcesosGraduacion')"><router-link to="/ProcesosGraduacion">Procesos</router-link></li>
  <li v-if="permitirMenu('ProcesosGraduacionGrupos')"><router-link to="/ProcesosGraduacionGrupos">Grupos</router-link></li>
  <li v-if="permitirMenu('ProcesosGraduacionFechas')"><router-link to="/ProcesosGraduacionFechas">Fechas de Pago</router-link></li>
  <li v-if="permitirMenu('ProcesosGraduacionInscripcion')"><router-link to="/ProcesosGraduacionInscripcion">Inscripción</router-link></li>
  <li v-if="permitirMenu('ProcesosGraduacionEstudiantes')"><router-link to="/ProcesosGraduacionEstudiantes">Estudiantes/Módulos</router-link></li>
  <li v-if="permitirMenu('ProcesosGraduacionNotas')"><router-link to="/ProcesosGraduacionNotas">Notas</router-link></li>
  <li v-if="isLoggedIn && isAdmin && permitirMenu('ProcesosGraduacionConfiguracion')"><router-link to="/ProcesosGraduacionConfiguracion">Configuración</router-link></li>
</ul>

<ul id="dd_gestiones" class="dropdown-content" style="font-size:12px">
  <li v-if="permitirOpcion('Proyeccion')"><router-link to="/Proyeccion">Proyecciones</router-link></li>
  <li v-if="permitirOpcion('MateriasProgramadas')"><router-link to="/MateriasProgramadas">Materias</router-link></li>
  <li v-if="permitirOpcion('Cruzadas')"><router-link to="/Cruzadas">Cruzadas</router-link></li>
  <li v-if="permitirOpcion('SinHorarios')"><router-link to="/SinHorarios">Materias Sin Horario</router-link></li>
  <li v-if="permitirOpcion('SinDocente')"><router-link to="/SinDocente">Horarios Sin Docente</router-link></li>
  <li v-if="isLoggedIn && (isAdmin || isAcademica || isGenerador) && permitirOpcion('Fechas')"><router-link to="/Fechas">Fechas Académicas</router-link></li>
  <li v-if="permitirOpcion('AulasMateria')"><router-link to="/AulasMateria">Aulas Por Materia</router-link></li>
</ul>

<ul id="dd_generacion" class="dropdown-content" style="font-size:12px">
  <li v-if="isLoggedIn && isAdmin && permitirOpcion('Generacion')"><router-link to="/Generacion">Generar Horarios</router-link></li>
  <li v-if="isLoggedIn && (isAdmin || isAcademica || isGenerador) && permitirOpcion('Grupos')"><router-link to="/Grupos">Grupos</router-link></li>
</ul>

<ul id="dd_fechas_virtual" class="dropdown-content" style="font-size:12px">
  <li v-if="isLoggedIn && permitirOpcion('ProgramacionVirtuales')"><router-link to="/FechasVirtuales">Programación</router-link></li>
  <li v-if="isLoggedIn && permitirOpcion('FechasNotasVirtuales') && permitirOpcion('FechasNotasVirtuales')"><router-link to="/FechasNotasVirtuales">Ingreso de Notas</router-link></li>
</ul>

<ul id="dd_configuracion" class="dropdown-content">
  <li v-if="isAdmin && isLoggedIn && permitirOpcion('Administracion')"><router-link to="/Administracion">Administración</router-link></li>
  <li v-if="isLoggedIn && (isAdmin || isAcademica) && permitirOpcion('Restricciones')"><router-link to="/Restricciones">Restricciones</router-link></li>
  <li v-if="isLoggedIn && (isAdmin) && permitirOpcion('AccesosUOnline')"><router-link to="/AccesosUOnline">Accesos UOnline</router-link></li>
</ul>

<nav class="blue-ugb white-text" style="padding-left:30px; padding-right:30px; margin-bottom:30px">
  <div class="nav-wrapper">
    <a href="#!" class="brand-logo"><img src="@/assets/logo_planner.png"></a>
    <ul class="right hide-on-med-and-down">
      <li v-if="isLoggedIn && permitirOpcion('Horarios')" v-bind:class="{'black': $route.name == 'Horarios'}"><router-link to="/Horarios">Horarios</router-link></li>
      <li v-if="isLoggedIn && permitirOpcion('Virtual')" v-bind:class="{'black': $route.name == 'Virtual'}"><router-link to="/Virtual">Virtual</router-link></li>
      <!-- <li v-if="isLoggedIn && permitirOpcion('FechasVirtuales')" v-bind:class="{'black': $route.name == 'FechasVirtuales'}"><router-link to="/FechasVirtuales">Fechas Virtual</router-link></li> -->
      
      <!-- Dropdown Trigger -->
      <li v-if="isLoggedIn && permitirOpcion('FechasVirtuales')"><a class="dropdown-trigger" href="#!" data-target="dd_fechas_virtual">Fechas Virtual<i class="material-icons right">arrow_drop_down</i></a></li>
      <li v-if="isLoggedIn && permitirOpcion('Gestion')"><a class="dropdown-trigger" href="#!" data-target="dd_gestiones">Gestión<i class="material-icons right">arrow_drop_down</i></a></li>
      <li v-if="isLoggedIn && permitirOpcion('ProcesosGraduacion')"><a class="dropdown-trigger" href="#!" data-target="dd_procesos">Procesos de Graduación<i class="material-icons right">arrow_drop_down</i></a></li>
      <li v-if="isLoggedIn && (isAdmin || isAcademica || isGenerador) && permitirOpcion('Generacion')"><a class="dropdown-trigger" href="#!" data-target="dd_generacion">Generación<i class="material-icons right">arrow_drop_down</i></a></li>
      <li v-if="isLoggedIn && 1 == 0"><a class="dropdown-trigger" href="#!" data-target="dd_parametrizaciones">Parametrizaciones<i class="material-icons right">arrow_drop_down</i></a></li>
      <li v-if="isLoggedIn && permitirOpcion('Configuracion')"><a class="dropdown-trigger" href="#!" data-target="dd_configuracion">Configuración<i class="material-icons right">arrow_drop_down</i></a></li>

      <li v-if="!isLoggedIn"><router-link to="/Login">Login</router-link></li>
      <li v-if="isLoggedIn"><a href="#!" @click="Logout">Salir</a></li>
    </ul>
  </div>
</nav>
</template>

<script>
  export default
  {
    async mounted()
    {
      
    },
    methods: {
      Logout() {
            localStorage.removeItem('token');
            localStorage.removeItem('usuario');
            localStorage.removeItem('regional');
            localStorage.removeItem('codigo');
            localStorage.removeItem('nombre');
            localStorage.removeItem('codcic');
            localStorage.removeItem('anio');    
            localStorage.removeItem('ciclo');
            localStorage.removeItem('ciclo');
            localStorage.removeItem('is_logged');
            localStorage.removeItem('is_admin');
            localStorage.removeItem('is_academica');
            localStorage.removeItem('codcil');
            localStorage.removeItem('procesoGraduacion');
            window.location = '/Login';
      },
      permitirMenu(opcion)
      {
        if(localStorage.getItem('procesoGraduacion') == undefined) return false;
        return JSON.parse(localStorage.getItem('procesoGraduacion')).find(x=>x.mpg_descripcion == opcion) != undefined;
      },
      permitirOpcion(opcion)
      {
        if(opcion == 'Home' || opcion == 'Login') return true;
        if(localStorage.getItem('menus') == undefined) return false;
        return JSON.parse(localStorage.getItem('menus')).find(x=>x == opcion) != undefined;
      }
    },
    computed:{
      isLoggedIn: function()
      {
        return localStorage.getItem('is_logged') == 'S';
      },
      isAdmin: function()
      {
        return localStorage.getItem('is_admin') == 'S';
      },
      isAcademica: function()
      {
        return localStorage.getItem('is_academica') == 'S';
      },
      isGenerador: function()
      {
        return localStorage.getItem('is_generador') == 'S';
      }
    }
  }
</script>

<style scoped>
  .nav-wrapper ul li a 
  {
    font-size:14px !important;
  }
</style>